/* eslint-disable */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import logo from "../../static/images/dptlogo.png"
import logo from "../../static/images/logouvision-dark.png"
import Menu from "./content/menu"

import { MenuCTA } from "./content/menu"

import { MenuCTAList } from "./content/menu"

import {} from "../../static/fonts/themify-icons/themify-icons.css"

import Navbar from "./Navbar"
const Header = ({ siteTitle, data }) => (
  <header>
    <nav className="navbar navbar-expand-md navbar-light fixed-top bg-white">
      <Link className="navbar-brand" to="/" title={siteTitle}>
        <img
          alt={"Logo"}
          height="48"
          src={logo}
          className="d-inline-block align-top img-fluid2"
          alt=""
          style={{height: '48px'}}
        />
        {/* {siteTitle} */}
      </Link>
      <button
        className="navbar-toggler  border-0v btn-primary2"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {" "}
        Learn more &nbsp;
        {/* <span className="navbar-toggler-icon" /> */}
        <svg
          className="bi bi-arrow-right-square-fill"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm5.646 10.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
          />
        </svg>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        {" "}
        {/* <Menu /> */}
        <div
          className="btn-group btn-group-md navbar-btn ml-auto mr-auto2 header-cta"
          role="group"
          aria-label="Large button group"
        >
          {/*     <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/problems/"
          >
            Problems
          </Link>    */}
          <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/solutions/"
          >
            Solutions
          </Link>
          <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/capabilities/"
          >
            Capabilities
          </Link>
          {/* </div>
        <div
            className="btn-group btn-group-md navbar-btn ml-auto mr-auto2 header-cta"
            role="group"             aria-label="Large button group"
          > */}
          <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/about/why"
          >
            Why
          </Link>{" "}
        </div>
        <div
          className="btn-group btn-group-md navbar-btn ml-auto mr-auto2 header-cta"
          role="group"
          aria-label="Large button group"
        >
          <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/news/"
          >
            News
          </Link>
          <Link
            className="btn btn-outline-info"
            style={{ marginRight: "10px" }}
            to="/blog/"
          >
            Blog
          </Link>{" "}
        </div>
        <MenuCTA />
        {/* <div
          id=""
          className="btn-group btn-group-md navbar-btn ml-auto mr-auto2 header-cta 
          "
          role="group"
          aria-label="Large button group"
        >{" "}
          <Link
            className=" nav-link btn btn-primary header-cta-signup  "
            to="/signup"
          >
            Sign Up
          </Link>
        </div> */}
        <ul className="navbar-nav ml-auto mr-auto d-md-none bg-white ">
          <MenuCTAList />
          {/* <li className="nav-item active header-cta-menu-item"> 

            <Link
              className="nav-link text-success"
              to="/solutions/people"
              title={"For Me"}
            >
              For Me
            </Link>
          </li> */}
          <li className="nav-item header-cta-menu-item">
            {/* <a className="nav-link" href="#">Link</a> */}
            <Link
              className="nav-link text-success"
              to="/solutions/business"
              title={"For Businesses"}
            >
              For Business
            </Link>
          </li>{" "}
          <li className="nav-item header-cta-menu-item">
            {/* <a className="nav-link" href="#">Link</a> */}
            <Link
              className="nav-link text-success"
              to="/solutions/providers"
              title={"For Providers"}
            >
              For Providers
            </Link>
          </li>
          <li className="nav-item header-cta-menu-item">
            {/* <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}

            <Link className="nav-link text-success " to="/solutions/partners">
              For Partners
            </Link>
          </li>
          <li className="nav-item">
            {/* <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}
            <Link className=" nav-link text-primary " to="/about">
              About
            </Link>
          </li>
          <li className="nav-item">
            {/* <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}
            <Link className=" nav-link text-info " to="/about/why">
              Why
            </Link>
          </li>
          <li className="nav-item  header-cta-menu-item">
            {/* <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}
            <Link className=" nav-link text-primary" to="/signup">
              Sign Up
            </Link>
          </li>
        </ul>
        <form className="form-inline mt-2 mt-md-0">
          {/* <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" /> */}
          {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
          {/* <div className={"get-started2 "}> */}
          {/* <Link
            className="btn btn-primary"
            to={data.site.siteMetadata.getStartedUrl}
          >
            Sign Up
          </Link> */}
          {/* <a href={data.site.siteMetadata.appUrl}>Sign Up</a> */}
          {/* </div> */}
        </form>
      </div>
    </nav>
  </header>
)

const Header2 = ({ siteTitle, data }) => (
  <header>
    <nav className="navbar navbar-expand-md navbar-light fixed-top bg-white">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="navbar-toggler-icon" /> */}
        <svg
          className="bi bi-arrow-right-square-fill"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm5.646 10.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
          />
        </svg>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto mr-auto">
          {" "}
          <div
            className="btn-group btn-group-md navbar-btn"
            role="group"
            aria-label="Large button group"
          >
            <Link
              className="btn btn-outline-info"
              style={{ marginRight: "10px" }}
              to="/about/why"
            >
              Why
            </Link>{" "}
          </div>
          <div
            className="btn-group btn-group-md navbar-btn"
            role="group"
            aria-label="Large button group"
          >
            {/* <Link
              className="btn btn-outline-success"
              to="/solutions/people"
              title={"For Me"}
            >
              For Me
            </Link> */}
            <Link
              className="btn btn-outline-success"
              to="/solutions/business"
              title={"For Business"}
            >
              For Business
            </Link>
            <Link className="btn btn-outline-success" to="/solutions/partners">
              For Partners
            </Link>
            {/* <a href={data.site.siteMetadata.appUrl}>Sign Up</a> */}
          </div>
          {/*
          <div className={"for-me"}>
            <Link to="/solutions/people" title={"For Me"}>
              For Me
            </Link>
          </div>
          <div className={"for-business"}>
            <Link to="/solutions/business" title={"For Business"}>
              For Business
            </Link>
          </div>
          <div className={"get-started"}>
            <Link to={data.site.siteMetadata.getStartedUrl}>Sign Up</Link>
          </div>

          <li className="nav-item active">
            <a className="nav-link" href="#">
              Home <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Link
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link disabled" href="#">
              Disabled
            </a>
          </li>
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</a>
            <div className="dropdown-menu" aria-labelledby="dropdown01">
              <a className="dropdown-item" href="#">Action</a>
              <a className="dropdown-item" href="#">Another action</a>
              <a className="dropdown-item" href="#">Something else here</a>
            </div>
          </li> */}
        </ul>
        <form className="form-inline my-2 my-lg-0">
          {/* <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" /> */}
          {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
          {/* <button type="button" className="btn btn-primary">Sign Up</button> */}

          <Link to="/signup" className="btn btn-primary">
            Sign Up
          </Link>
        </form>
      </div>
    </nav>

    <div className={"container d-none"}>
      <div className={"top-menu"}>
        <div className={"logo"}>
          <Link to="/" title={siteTitle}>
            <img alt={"Logo"} src={logo} />
          </Link>
        </div>
        {/* 
        <div className={"for-me"}>
          <Link to="/solutions/people" title={"For Me"}>
            For Me
          </Link>
        </div> */}
        <div className={"for-business"}>
          <Link to="/solutions/business" title={"For Business"}>
            For Business
          </Link>
        </div>
        <div className={"get-started"}>
          <Link to="/signup">Sign Up</Link>
          {/* <a href={data.site.siteMetadata.appUrl}>Sign Up</a> */}
        </div>
      </div>
    </div>
    <Navbar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
