import React from "react"
import { Link } from "gatsby"

const peopleLink = { url: "/solutions/people", title: "For Me" }
const businessLink = { url: "/solutions/business", title: "For Businesses" }
const providersLink = { url: "/solutions/providers", title: "For Providers" }
const partnerLink = { url: "/solutions/partners", title: "For Partners" }

const requestReportLink = {
  url: "/request-assessment",
  title: "Get an Assessment",
}

const requestDemoLink = { url: "/lets-talk", title: "Let's Talk" }

const Menu = () => (
  <div
    className="btn-group btn-group-md navbar-btn ml-auto mr-auto2  header-cta-menu-item"
    role="group"
    aria-label="Large button group"
  >
    {/* <Link
      className="btn btn-outline-success"
      to={peopleLink.url}
      title={peopleLink.title}
    >
      {peopleLink.title}
    </Link> */}
    <Link
      className="btn btn-outline-success"
      to={businessLink.url}
      title={businessLink.title}
    >
      {businessLink.title}
    </Link>
    <Link
      className="btn btn-outline-success"
      to={providersLink.url}
      title={providersLink.title}
    >
      {providersLink.title}
    </Link>
    {/* <Link className="btn btn-outline-success" 
          
          to={partnerLink.url}
          title={partnerLink.title}
          >
            {partnerLink.title}
          </Link> */}
    {/* <a href={data.site.siteMetadata.appUrl}>Sign Up</a> */}
  </div>
)

export const MenuCTA = () => (
  <div>
    {/* <div
    className="btn-group btn-group-md navbar-btn ml-auto mr-auto2  header-cta-menu-item"
    role="group"
    aria-label="Large button group"
  >
    <Link
      className="btn btn-outline-primary"
      to={requestReportLink.url}
      title={requestReportLink.title}
    >
      {requestReportLink.title}
    </Link>
    </div>  */}
    <div
      className="btn-group btn-group-md navbar-btn ml-auto mr-auto2  header-cta-menu-item"
      role="group"
      aria-label="Large button group"
    >
      <Link
        className="btn btn-primary"
        to={requestDemoLink.url}
        title={requestDemoLink.title}
      >
        {requestDemoLink.title}
      </Link>
    </div>{" "}
  </div>
)

export const MenuCTAList = () => (
  <>
    <li className="nav-item header-cta-menu-item">
      <Link
        className="nav-link text-primary"
        to={requestReportLink.url}
        title={requestReportLink.title}
      >
        {requestReportLink.title}
      </Link>
    </li>

    <li className="nav-item header-cta-menu-item">
      <Link
        className="nav-link text-primary btn btn-primary header-cta-signup"
        to={requestDemoLink.url}
        title={requestDemoLink.title}
      >
        {requestDemoLink.title}
      </Link>
    </li>
  </>
)

export const MenuPanels = () => (
  <>
    <div className="container text-center border-primary pb-4">
      {/* <div className="page-header">
        <h1>Panels with nav tabs.<span className="pull-right label label-default">:)</span></h1>
    </div> */}
      <ul
        className="nav nav-pills mb-3  nav-justified border border-primary"
        id="pills-tab"
        role="tablist"
      >
        {/* <li className="nav-item">
          <a
            className="nav-link active rounded-0"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-me"
            role="tab"
            aria-controls="pills-me"
            aria-selected="true"
          >
            For People &amp; End Users
          </a>
        </li> */}
        <li className="nav-item">
          <a
            className="nav-link rounded-0"
            id="pills-customers-tab"
            data-toggle="pill"
            href="#pills-customers"
            role="tab"
            aria-controls="pills-customers"
            aria-selected="false"
          >
            For Business Customers
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link rounded-0"
            id="pills-business-tab"
            data-toggle="pill"
            href="#pills-business"
            role="tab"
            aria-controls="pills-business"
            aria-selected="false"
          >
            For Service Providers
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link rounded-0"
            id="pills-partners-tab "
            data-toggle="pill"
            href="#pills-partners"
            role="tab"
            aria-controls="pills-partners"
            aria-selected="false"
          >
            For Our Partners
          </a>
        </li>
      </ul>
    </div>
  </>
)

export default Menu
